body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, #root>div {
  height: 100%
}

.deal-banner > img {
  height: 100px !important;
  width: 180px !important;
}

.sidemenu > .menu {
    width: auto !important;
    height: -webkit-fill-available;
}

.ui.vertical.menu>.item {
    color: white;
}

.ui.menu .active.item {
    color: #2668ce !important;
    font-weight: bold !important;
}
.color-picker-body {
  margin: 0;
  padding: 25px;
  font: normal 18px/1.4 Arial, sans-serif;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}

.buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.value button, .buttons button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.branch_tab > .menu{
  display: flex;
  flex-wrap: wrap;
}

.branch_tab > .menu > .item{
  margin-right: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bolder !important;
}

.certificate-form > div, .certificate-form > div > div{
  opacity: 1 !important;
}

.certificate-form > div > div > i {
    opacity: 0 !important;
}

.input > input {
  height: 39px;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.field > label {
  margin-right: 5px;
}

.field-expired_date>.field {
  width: 100%;
}

.datepicker-basic {
  width: 100%;
}
