.color-picker-body {
  margin: 0;
  padding: 25px;
  font: normal 18px/1.4 Arial, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.buttons {
  display: flex;
  gap: 10px;
}

.value button, .buttons button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}